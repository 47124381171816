import platform from '../platform';
import { ENV_PROD, ENV_STAGING, STAGING_STATIC_HOST, STATIC_HOST } from './constants';
import { isSameProduct } from './selectors';

export function onReady(fn) {
  if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', fn);
  } else {
    fn();
  }
}

export function getMainJs(): HTMLScriptElement {
  return document.querySelector(
    [
      `script[src^="https://${STATIC_HOST}"]`,
      `script[src^="https://${STAGING_STATIC_HOST}"]`,
      `script[src^="http://${STATIC_HOST}"]`,
      `script[src^="http://${STAGING_STATIC_HOST}"]`
    ].join(',')
  ) as HTMLScriptElement;
}
/**
 *
 * @returns array of two elements [merchantId, env];
 */
export function resolveEnvAndMerchant() {
  const script = getMainJs();
  if (!script) return [];
  const url = new URL(script.src);
  const env = url.host.startsWith(ENV_STAGING) ? ENV_STAGING : ENV_PROD;
  const merchantId = url.pathname.split('/')[1];

  if (!env && !merchantId) return [];

  return [merchantId, env, script];
}

export const safeProductId = product => {
  if (!product) return '';
  let productId = `${product.id || product}`;
  if (platform?.shopify_selling_plans) {
    // we can't avoid make offer request since we need to know the upsell group and autoship by default
    // cart offer contains <product>:<cart_id>, we care about product only
    productId = productId.split(':')[0];
  }
  return productId;
};

/**
 * Returns the OG frequency if platform is running on selling plans
 * @param initialFrequency
 * @param config
 */
export const safeOgFrequency = (initialFrequency, config) => {
  if (platform.shopify_selling_plans) {
    const ix = config.frequencies?.indexOf(initialFrequency);
    if (ix >= 0 && config.frequenciesEveryPeriod[ix]) {
      return config.frequenciesEveryPeriod[ix];
    }
  }
  return initialFrequency;
};

export const frequencyToSellingPlan = (ogFrequency, config) => {
  // og frequency contains underscore
  if (!`${ogFrequency}`.includes('_')) return ogFrequency;
  const ix = config.frequenciesEveryPeriod?.indexOf(ogFrequency);
  if (ix >= 0 && config.frequenciesEveryPeriod[ix]) {
    return config.frequencies[ix];
  }

  return ogFrequency;
};

/**
 * Attempts to auto initialize the offer library reading the merchantId and env from
 * integration script i.e. <script src="http://static.ordergroove...."/>.
 * Useful when local develop using http redirects
 */
export function autoInitializeOffers(offers) {
  if (offers.isReady()) return;

  console.info('OG offers are auto initializing');

  const [merchantId, env] = resolveEnvAndMerchant();
  if (!env && !merchantId) return;
  const script = document.createElement('script');
  script.onload = () => console.info('OG pull initialization chunk for merchant', merchantId, env);
  script.onerror = () => offers.initialize(merchantId, env);
  script.src = `${window.location.protocol}//${
    env === ENV_PROD ? STATIC_HOST : STAGING_STATIC_HOST
  }/${merchantId}/main.js?initOnly=true`;

  document.head.appendChild(script);
}

export const clearCookie = cookieId => {
  // clear existing OG auth cookie
  document.cookie = `${cookieId}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function getCookieValue(cookieId) {
  const cookie = document.cookie.match(`(^|;) ?${cookieId}=([^;]*)(;|$)`);
  return cookie ? cookie[2] : null;
}
export const isOgFrequency = frequency => !!(frequency && frequency?.includes('_'));

export const getFirstSellingPlan = (frequencies = []) => frequencies?.[0] || null;

export const hasShopifySellingPlans = (sellingPlans = [], frequenciesEveryPeriod = []) =>
  !!(platform?.shopify_selling_plans && sellingPlans.length && frequenciesEveryPeriod.length);

export const mapFrequencyToSellingPlan = (sellingPlans, frequenciesEveryPeriod, frequency) => {
  if (sellingPlans.length !== frequenciesEveryPeriod.length) {
    return null;
  }

  const index = frequenciesEveryPeriod.findIndex(it => it === frequency);

  if (index >= 0) {
    return sellingPlans[index];
  }

  return null;
};

export function getOrCreateHidden(parent, name, value) {
  let input = parent.querySelector(`[name="${name}"]`);
  if (input && !value) {
    input.remove();
    return;
  }
  if (!input && value) {
    input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    parent.appendChild(input);
  }
  if (input) {
    input.value = value;
  }
}

/**
 * Returns the first matching product if it exists, or an empty object if it doesn't.
 * @param state - the optedin/optedout state array to search
 * @param product - the product to search for
 * @returns {[any, any[]]} - a two item array where the first item is the matching product and the second is the remaining items from the original state.
 */
export function getMatchingProductIfExists(state, product) {
  const [[oldone], rest] = state.reduce(
    (acc, val) => acc[isSameProduct(product, val) ? 0 : 1].push(val) && acc,
    [[], []]
  );

  return [oldone || {}, rest || []];
}
